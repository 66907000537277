import { Injectable } from '@angular/core';
import { firstValueFrom, map, switchMap } from 'rxjs';
import {
  CreateNotificationDto,
  CreateOneNotificationGQL,
  CreatedNotificationGQL,
  DeleteOneNotificationGQL,
  DeleteOneNotificationInput,
  DeletedOneNotificationGQL,
  NotificationFilter,
  NotificationGQL,
  NotificationSortFields,
  NotificationsGQL,
  SortDirection,
  UpdateOneNotificationGQL,
  UpdateOneNotificationInput,
  UpdatedOneNotificationGQL
} from 'src/app/graphql/frontend-data-graphql';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private notificationsGQL: NotificationsGQL,
    private notificationGQL: NotificationGQL,
    private createGQL: CreateOneNotificationGQL,
    private updateGQL: UpdateOneNotificationGQL,
    private deleteGQL: DeleteOneNotificationGQL,
    private createdGQL: CreatedNotificationGQL,
    private updatedGQL: UpdatedOneNotificationGQL,
    private deletedGQL: DeletedOneNotificationGQL
  ) {}

  findMany(filter: NotificationFilter = {}) {
    return this.notificationsGQL.fetch({
      filter,
      paging: { first: 50 },
      sorting: { field: NotificationSortFields.Updated, direction: SortDirection.Desc }
    });
  }

  findManyQuery(filter: NotificationFilter = {}) {
    return this.notificationsGQL.watch({
      filter,
      paging: { first: 50 },
      sorting: { field: NotificationSortFields.Updated, direction: SortDirection.Desc }
    });
  }

  findOne(id: string) {
    return this.notificationGQL.fetch({ id });
  }

  create(input: CreateNotificationDto) {
    return this.createGQL.mutate({ input });
  }

  update(input: UpdateOneNotificationInput) {
    return this.updateGQL.mutate({ input });
  }

  delete(input: DeleteOneNotificationInput) {
    return this.deleteGQL.mutate({ input });
  }

  observeCreated(filter: NotificationFilter) {
    return this.createdGQL.subscribe().pipe(map(({ data }) => data!.createdNotification));
  }

  observeUpdated(filter: NotificationFilter) {
    return this.updatedGQL.subscribe().pipe(map(({ data }) => data!.updatedOneNotification));
  }

  observeDeleted(filter: NotificationFilter) {
    return this.deletedGQL.subscribe().pipe(map(({ data }) => data!.deletedOneNotification));
  }
}
